import { tv } from 'tailwind-variants';

export const comboboxVariants = tv({
  slots: {
    base: 'group relative flex min-h-12 gap-1 rounded-md border-1 border-solid border-gray-500 bg-white px-2',
    button: 'inline-flex h-12 w-8 items-center justify-center',
    chip: 'min-w-fit self-center',
    formControl: 'min-w-20',
    icon: 'size-6 transition-transform group-data-[open=true]:rotate-180',
    input: 'mr-auto min-w-8 flex-1 bg-transparent py-1 outline-none',
    listBox: '',
    popover: '',
  },
  variants: {
    isDisabled: {
      true: {
        base: 'border-gray-300 bg-gray-100 text-gray-500',
      },
    },
    isFullWidth: {
      true: {
        base: 'w-full',
      },
    },
    size: {
      md: {},
      sm: {
        base: 'min-h-6',
        button: 'h-8 w-6',
        icon: 'size-5',
      },
    },
  },
});
