import type { ComponentPropsWithRef } from 'react';
import { Children } from 'react';

import { ChevronRightIcon } from '@heroicons/react/24/solid';
import { twMerge } from 'tailwind-merge';

type OuterProps = ComponentPropsWithRef<'nav'>;

type BreadcrumbProps = OuterProps;

export const Breadcrumbs = (props: BreadcrumbProps) => {
  const { className, children, ref, ...otherProps } = props;

  const items = Children.toArray(children)
    .flatMap((child, index) => [
      <li
        className="flex items-center"
        // eslint-disable-next-line react/no-array-index-key
        key={`child-${index}`}
      >
        {child}
      </li>,
      <ChevronRightIcon
        className="mx-3 size-4 h-5 text-gray-400"
        // eslint-disable-next-line react/no-array-index-key
        key={`child-${index}-separator`}
      />,
    ])
    .slice(0, -1);

  return (
    <nav ref={ref}>
      <ol
        className={twMerge('grid grid-flow-col justify-start pl-20 text-sm', className)}
        {...otherProps}
      >
        {items}
      </ol>
    </nav>
  );
};
