import type { TabListState } from '@react-stately/tabs';
import type { ItemProps, Node } from '@react-types/shared';
import type { ComponentProps, ElementType } from 'react';
import type { VariantProps } from 'tailwind-variants';
import React, { use, useEffect, useMemo, useRef } from 'react';

import { FocusRing } from '@react-aria/focus';
import { useHover } from '@react-aria/interactions';
import { useTab } from '@react-aria/tabs';
import { mergeProps } from '@react-aria/utils';
import { Item } from '@react-stately/collections';
import { useTabListState } from '@react-stately/tabs';
import { tv } from 'tailwind-variants';

import type { InternalComponentProps, OverridableComponent, OverrideProps } from '../../types';
import { mergeRefs } from '../../utils';
import { TabContext } from './TabContext';

const tabVariants = tv({
  slots: {
    base: 'cursor-pointer rounded-sm px-2 py-1 text-gray-700 data-[disabled=true]:cursor-not-allowed data-[disabled=true]:text-gray-400',
    focusRing: '',
  },
  variants: {
    variant: {
      solid: {
        base: [
          'data-[selected=true]:rounded-sm',
          'data-[hover=true]:border-orange-default',
          'data-[hover=true]:bg-transparent',
          'data-[hover=true]:text-orange-default',
          'data-[hover=true]:hover:bg-orange-default/20',
          'data-[hover=true]:active:bg-orange-default/10',
          'data-[selected=true]:bg-orange-default',
          'data-[selected=true]:text-white',
          'data-[selected=true]:outline-none',
        ],
      },
      underlined: {
        base: [
          'bg-transparent',
          'text-[14px]',
          'font-medium',
          'text-gray-400',
          'data-[hover=true]:bg-transparent',
          'data-[hover=true]:text-gray-800',
          'data-[hover=true]:underline',
          'data-[hover=true]:decoration-2',
          'data-[hover=true]:underline-offset-8',
          'data-[selected=true]:text-orange-default',
          'data-[selected=true]:underline',
          'data-[selected=true]:decoration-2',
          'data-[selected=true]:underline-offset-8',
        ],
      },
    },
  },
});

interface InternalTabProps extends Omit<ComponentProps<'div'>, 'children'> {
  item: Node<object>;
  state: TabListState<object>;
  isDisabled?: boolean;
  focusRingClassName?: string;
  variant?: VariantProps<typeof tabVariants>['variant'];
}

// @private
function InternalTab(props: InternalTabProps) {
  const { item, state, focusRingClassName, className, variant } = props;
  const { key, rendered, props: itemProps } = item;

  const ref = useRef<any>(null);
  const { tabProps, isSelected, isDisabled } = useTab({ key }, state, ref);

  const { hoverProps, isHovered } = useHover({
    ...props,
  });

  const Component: ElementType = itemProps.as ?? (itemProps.href ? 'a' : 'div');

  const styles = useMemo(
    () =>
      tabVariants({
        className,
        variant,
      }),
    [className, variant],
  );

  return (
    <FocusRing focusRingClass={styles.base({ className: focusRingClassName })}>
      <Component
        {...mergeProps(tabProps, hoverProps)}
        className={styles.base({ className })}
        data-disabled={isDisabled}
        data-hover={isHovered}
        data-selected={isSelected}
        ref={ref}
      >
        {rendered}
      </Component>
    </FocusRing>
  );
}

export interface TabProps
  extends Omit<InternalTabProps, 'state' | 'item' | 'title'>,
    ItemProps<object> {
  as?: ElementType;
}

export const Tab = Item as (props: TabProps) => React.JSX.Element;

export interface TabListTypeMap<AdditionalProps = {}, RootComponent extends ElementType = 'div'> {
  props: AdditionalProps & {};
  defaultComponent: RootComponent;
}

export type TabListProps<
  RootComponent extends ElementType = TabListTypeMap['defaultComponent'],
  AdditionalProps = {},
> = OverrideProps<TabListTypeMap<AdditionalProps, RootComponent>, RootComponent>;

type InternalTabListProps<
  RootComponent extends ElementType = TabListTypeMap['defaultComponent'],
  AdditionalProps = {},
> = InternalComponentProps<TabListTypeMap<AdditionalProps, RootComponent>>;

const tabListVariants = tv({
  base: 'relative m-0 flex flex-1 select-none items-center p-0 align-top outline-none',
});

export const TabList = (<
  BaseComponentType extends ElementType = TabListTypeMap['defaultComponent'],
>(
  inProps: InternalTabListProps<BaseComponentType>,
) => {
  const { as: Component = 'div', children, className, ref } = inProps;

  const tabContext = use(TabContext);
  const { refs, tabState, tabProps } = tabContext;
  const { setTabListState } = tabState;
  const { tablistRef } = refs;

  // Pass original Tab props but override children to create the collection.
  const state = useTabListState({ ...tabProps, children });

  useEffect(() => {
    // Passing back to root as useTabPanel needs the TabListState
    setTabListState(state);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.disabledKeys, state.selectedItem, state.selectedKey, children]);

  const styles = useMemo(() => tabListVariants({ className }), [className]);

  return (
    <Component
      className={styles}
      ref={mergeRefs(tablistRef, ref)}
    >
      {[...state.collection].map((item) => (
        <InternalTab
          {...item.props}
          item={item}
          key={item.key}
          state={state}
          variant={tabProps.variant}
        />
      ))}
    </Component>
  );
}) as OverridableComponent<TabListTypeMap>;
