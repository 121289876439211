import type { QueryClientConfig } from '@tanstack/react-query';

import { defaultShouldDehydrateQuery, isServer, QueryClient } from '@tanstack/react-query';
import SuperJSON from 'superjson';

interface QueryError {
  error: string;
  message: string;
  path: string;
  status: number;
  timestamp: string;
}

function makeQueryClient(clientConfig?: QueryClientConfig) {
  return new QueryClient({
    ...clientConfig,
    defaultOptions: {
      queries: {
        retry(failureCount, error) {
          if ((error as unknown as QueryError).status >= 400) {
            return false;
          }
          if (failureCount < 2) {
            return true;
          }
          return false;
        },
        // 5 minutes
        staleTime: 300_000,
      },
      dehydrate: {
        serializeData: SuperJSON.serialize,
        shouldDehydrateQuery: (query) =>
          defaultShouldDehydrateQuery(query) || query.state.status === 'pending',
      },
      hydrate: {
        deserializeData: SuperJSON.deserialize,
      },
      ...clientConfig?.defaultOptions,
    },
  });
}

let browserQueryClient: QueryClient | undefined = undefined;

export function getQueryClient(clientConfig?: QueryClientConfig) {
  if (isServer) {
    // Server: always make a new query client
    return makeQueryClient(clientConfig);
  }
  // Browser: make a new query client if we don't already have one
  // This is very important, so we don't re-make a new client if React
  // suspends during the initial render. This may not be needed if we
  // have a suspense boundary BELOW the creation of the query client
  browserQueryClient ??= makeQueryClient(clientConfig);

  return browserQueryClient;
}
