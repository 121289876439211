/* eslint-disable @typescript-eslint/no-unnecessary-condition */
/**
 * Hopefully this will be added directly to the react aria and we can remove this monster
 * @private
 */

import type { MenuTriggerAction } from '@react-types/combobox';
import type { FocusStrategy, Selection } from '@react-types/shared';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { useFormValidationState } from '@react-stately/form';
import { useMenuTriggerState } from '@react-stately/menu';
import { useControlledState } from '@react-stately/utils';

import type { MultipleComboboxProps, MultipleComboboxState } from './useMultipleCombobox';
import { useMultiSelectListState } from '../../hooks';
import { filterCollection } from '../../utils';

export interface MultipleComboBoxStateProps<T> extends MultipleComboboxProps<T> {
  /** The filter function used to determine if a option should be included in the combo box list. */
  defaultFilter?: (textValue: string, inputValue: string) => boolean;
  /** Whether the combo box allows the menu to be open when the collection is empty. */
  allowsEmptyCollection?: boolean;
  /** Whether the combo box menu should close on blur. */
  shouldCloseOnBlur?: boolean;
  /** Keep menu open after selection.
   * @default false
   */
  keepMenuOpen?: boolean;
}

/**
 * Pulled directly from the following library and augmented for our needs:
 *
 * https://github.com/so99ynoodles/headless-react/blob/main/packages/combobox/src/hooks/useMultiComboBoxState.tsx
 *
 * Original created by react-aria:
 *
 * https://github.com/adobe/react-spectrum/blob/main/packages/%40react-stately/combobox/src/useComboBoxState.ts
 */
export function useMultipleComboboxState<T extends object>(
  props: MultipleComboBoxStateProps<T>,
): MultipleComboboxState<T> {
  const {
    allowsEmptyCollection = false,
    allowsCustomValue,
    defaultFilter,
    defaultItems,
    defaultInputValue = '',
    inputValue: inputValueProp,
    items,
    menuTrigger = 'input',
    onInputChange,
    onOpenChange,
    onSelectionChange,
    selectedKeys: selectedKeysProp,
    shouldCloseOnBlur = true,
    keepMenuOpen = false,
  } = props;

  const lastSelectedKeys = useRef(props.selectedKeys ?? props.defaultSelectedKeys ?? null);

  const [showAllItems, setShowAllItems] = useState(false);
  const [isFocused, setIsFocused] = useState(false);
  const [focusStrategyState, setFocusStrategyState] = useState<FocusStrategy | null>(null);
  const [inputValue, setInputValue] = useControlledState(
    inputValueProp!,
    defaultInputValue,
    onInputChange,
  );
  const [lastValue, setLastValue] = useState(inputValue);

  const resetInputValue = () => {
    setLastValue('');
    setInputValue('');
  };

  const valueRef = useRef(inputValue);
  const triggerTypeRef = useRef('focus' as MenuTriggerAction);

  const triggerState = useMenuTriggerState({
    ...props,
    defaultOpen: undefined,
    isOpen: undefined,
    onOpenChange: (isOpen: boolean) => {
      onOpenChange?.(isOpen, isOpen ? triggerTypeRef.current : undefined);
    },
  });

  const commitValue = () => {
    const itemTexts = [...collection].map((item) => item.textValue);

    if (allowsCustomValue && !itemTexts.includes(inputValue)) {
      commitCustomValue();
    } else {
      commitSelection();
    }
  };

  const {
    collection,
    selectionManager,
    selectedKeys,
    setSelectedKeys,
    selectedItems,
    disabledKeys,
  } = useMultiSelectListState({
    ...props,
    items: items ?? defaultItems,
    onSelectionChange: (keys: Selection) => {
      onSelectionChange?.(keys);
      resetInputValue();
    },
  });

  // Preserve original collection so we can show all items on demand
  const originalCollection = collection;
  const filteredCollection = useMemo(
    () =>
      // No default filter if items are controlled.
      items != null || !defaultFilter
        ? collection
        : filterCollection(collection, inputValue, defaultFilter),
    [collection, inputValue, defaultFilter, items],
  );
  const [lastCollection, setLastCollection] = useState(filteredCollection);

  const updateLastCollection = useCallback(() => {
    setLastCollection(showAllItems ? originalCollection : filteredCollection);
  }, [showAllItems, originalCollection, filteredCollection]);

  const closeMenu = useCallback(() => {
    if (triggerState.isOpen) {
      updateLastCollection();
      triggerState.close();
    }
  }, [triggerState, updateLastCollection]);

  const open = (focusStrategy?: FocusStrategy | null, trigger?: MenuTriggerAction | null) => {
    const displayAllItems =
      trigger === 'manual' || (trigger === 'focus' && menuTrigger === 'focus');
    // Prevent open operations from triggering if there is nothing to display
    // Also prevent open operations from triggering if items are uncontrolled but defaultItems is empty, even if displayAllItems is true.
    // This is to prevent comboboxes with empty defaultItems from opening but allow controlled items comboboxes to open even if the initial list is empty (assumption is user will provide swap the empty list with a base list via onOpenChange returning `menuTrigger` manual)
    if (
      allowsEmptyCollection ||
      filteredCollection.size > 0 ||
      (displayAllItems && originalCollection.size > 0) ||
      props.items
    ) {
      if (displayAllItems && !triggerState.isOpen && props.items === undefined) {
        // Show all items if menu is manually opened. Only care about this if items are undefined
        setShowAllItems(true);
      }

      triggerTypeRef.current = trigger!;
      setFocusStrategyState(focusStrategy!);
      triggerState.open();
    }
  };

  const commitCustomValue = () => {
    setSelectedKeys(new Set([...selectedKeys, inputValue]));
    resetInputValue();
    closeMenu();
  };

  const commitSelection = () => {
    // If multiple things are controlled, call onSelectionChange
    if (selectedKeysProp !== undefined && inputValueProp !== undefined) {
      onSelectionChange?.(selectedKeys);

      // Stop menu from reopening from useEffect
      const itemText = collection.getItem(selectionManager.focusedKey!)?.textValue ?? '';

      valueRef.current = itemText;
    }

    // If only a single aspect of combobox is controlled, reset input value and close menu for the user
    resetInputValue();
    closeMenu();
  };

  const commit = () => {
    if (triggerState.isOpen && selectionManager.focusedKey != null) {
      // Reset inputValue and close menu here if the selected key is already the focused key. Otherwise
      // fire onSelectionChange to allow the application to control the closing.
      if ([...selectedKeys].includes(selectionManager.focusedKey)) {
        commitSelection();
      } else {
        setSelectedKeys(new Set([...selectedKeys, selectionManager.focusedKey]));
      }
    } else {
      commitValue();
    }
  };

  const valueOnFocus = useRef(inputValue);

  const setFocused = (focused: boolean) => {
    if (focused) {
      valueOnFocus.current = inputValue;
      if (menuTrigger === 'focus') {
        open(null, 'focus');
      }
    } else {
      if (shouldCloseOnBlur) {
        commitValue();
      }

      if (inputValue !== valueOnFocus.current) {
        validation.commitValidation();
      }
    }

    setIsFocused(focused);
  };

  const revert = () => {
    if (allowsCustomValue && [...selectedKeys].length > 0) {
      commitCustomValue();
    } else {
      commitSelection();
    }
  };

  const toggle = (focusStrategy?: FocusStrategy | null, trigger?: MenuTriggerAction | null) => {
    const displayAllItems =
      trigger === 'manual' || (trigger === 'focus' && menuTrigger === 'focus');

    // If the menu is closed and there is nothing to display, early return so toggle isn't called to prevent extraneous onOpenChange
    if (
      !(
        allowsEmptyCollection ||
        filteredCollection.size > 0 ||
        (displayAllItems && originalCollection.size > 0) ||
        items
      ) &&
      !triggerState.isOpen
    ) {
      return;
    }

    if (displayAllItems && !triggerState.isOpen && items === undefined) {
      // Show all items if menu is toggled open. Only care about this if items are undefined
      setShowAllItems(true);
    }

    // Only update the menuOpenTrigger if menu is currently closed
    if (!triggerState.isOpen) {
      triggerTypeRef.current = trigger!;
    }

    triggerState.toggle(focusStrategy);
  };

  // intentional omit dependency array, want this to happen on every render
  // eslint-disable-next-line react-compiler/react-compiler
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    // Open and close menu automatically when the input value changes if the input is focused,
    // and there are items in the collection or allowEmptyCollection is true.
    if (
      isFocused &&
      (filteredCollection.size > 0 || allowsEmptyCollection) &&
      !triggerState.isOpen &&
      inputValue !== lastValue &&
      menuTrigger !== 'manual'
    ) {
      open(null, 'input');
    }

    // Close the menu if the collection is empty. Don't close menu if filtered collection size is 0
    // but we are currently showing all items via button press
    if (
      !showAllItems &&
      !allowsEmptyCollection &&
      triggerState.isOpen &&
      filteredCollection.size === 0
    ) {
      closeMenu();
    }

    // Close when an item is selected.
    if (!keepMenuOpen && selectedKeys != null && selectedKeys !== lastSelectedKeys.current) {
      closeMenu();
    }

    // Clear focused key when input value changes and display filtered collection again.
    if (inputValue !== lastValue) {
      selectionManager.setFocusedKey(null);
      setShowAllItems(false);
    }

    // If the selectedKey changed, update the input value.
    // Do nothing if both inputValue and selectedKey are controlled.
    // In this case, it's the user's responsibility to update inputValue in onSelectionChange.
    if (
      selectedKeys !== lastSelectedKeys.current &&
      (props.inputValue === undefined || props.selectedKeys === undefined)
    ) {
      resetInputValue();
    } else if (lastValue !== inputValue) {
      setLastValue(inputValue);
    }

    lastSelectedKeys.current = selectedKeys;
  });

  const validation = useFormValidationState({
    ...props,
    value: useMemo(() => ({ inputValue, selectedKeys }), [inputValue, selectedKeys]),
  });

  const displayedCollection = useMemo(() => {
    if (triggerState.isOpen) {
      if (showAllItems) {
        return originalCollection;
      } else {
        return filteredCollection;
      }
    } else {
      return lastCollection;
    }
  }, [triggerState.isOpen, originalCollection, filteredCollection, showAllItems, lastCollection]);

  return {
    ...triggerState,
    ...validation,
    collection: displayedCollection,
    commit,
    disabledKeys,
    focusStrategy: focusStrategyState!,
    inputValue,
    isFocused,
    open,
    resetInputValue,
    revert,
    selectedItems,
    selectedKeys,
    selectionManager,
    setFocused,
    setInputValue,
    setSelectedKeys,
    toggle,
  };
}
