import type { VirtualizerItemOptions } from '@react-aria/virtualizer';
import type { LayoutInfo } from '@react-stately/virtualizer';
import type { Node, SectionProps as ReactAriaSectionProps } from '@react-types/shared';
import type { ReactNode } from 'react';
import type { VariantProps } from 'tailwind-variants';
import { useMemo, useRef } from 'react';

import { useListBoxSection } from '@react-aria/listbox';
import { layoutInfoToStyle, useVirtualizerItem } from '@react-aria/virtualizer';
import { twMerge } from 'tailwind-merge';
import { tv } from 'tailwind-variants';

const listBoxSectionVariants = tv({
  slots: {
    base: 'flex flex-col gap-1',
    group: 'flex flex-col gap-1',
    heading: 'bg-actions-hover px-2 py-1 text-sm',
  },
  variants: {
    size: {
      md: {},
      sm: {
        heading: 'text-xs',
      },
    },
  },
});

export interface ListBoxBaseSectionProps extends ReactAriaSectionProps<object> {
  className?: string;
}

interface SectionProps
  extends Omit<VirtualizerItemOptions, 'ref' | 'layoutInfo'>,
    VariantProps<typeof listBoxSectionVariants> {
  layoutInfo: LayoutInfo;
  headerLayoutInfo: LayoutInfo | null;
  item: Node<object>;
  children?: ReactNode;
  className?: string;
}

/** @private */
export function ListBoxBaseSection(props: SectionProps) {
  const {
    children,
    layoutInfo,
    headerLayoutInfo,
    virtualizer,
    item,
    size = 'md',
    className,
  } = props;

  const { headingProps, groupProps } = useListBoxSection({
    heading: item.rendered,
    'aria-label': item['aria-label'],
  });

  const headerRef = useRef<HTMLDivElement | null>(null);

  const styles = useMemo(
    () =>
      listBoxSectionVariants({
        className: twMerge(className, item.props.className),
        size: item.props.size ?? size,
      }),
    [className, item.props.className, item.props.size, size],
  );

  useVirtualizerItem({
    layoutInfo: headerLayoutInfo,
    virtualizer,
    ref: headerRef,
  });

  return (
    <>
      {headerLayoutInfo ? (
        <div
          ref={headerRef}
          role="presentation"
          style={layoutInfoToStyle(headerLayoutInfo, 'ltr')}
        >
          {item.rendered ? (
            <div
              {...headingProps}
              className={styles.heading()}
            >
              {item.rendered}
            </div>
          ) : null}
        </div>
      ) : null}
      <div
        {...groupProps}
        className={styles.group()}
        style={layoutInfoToStyle(layoutInfo, 'ltr')}
      >
        {children}
      </div>
    </>
  );
}
