import type { ChangeEvent } from 'react';
import { useEffect, useImperativeHandle, useRef } from 'react';

import { twMerge } from 'tailwind-merge';

import styles from './CheckboxInput.module.css';

type OuterProps = React.JSX.IntrinsicElements['div'];

export type CheckboxInputProps = {
  checked?: boolean;
  disabled?: boolean;
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
  indeterminate?: boolean;
  disableStopPropagation?: boolean;
} & Omit<OuterProps, 'onChange'>;

export const CheckboxInput = (props: CheckboxInputProps) => {
  const {
    checked = false,
    disabled = false,
    indeterminate = false,
    onChange,
    className,
    children,
    disableStopPropagation = false,
    ref,
    ...otherProps
  } = props;
  const checkboxRef = useRef<HTMLInputElement>(null);

  useImperativeHandle(ref, () => checkboxRef.current!);

  useEffect(() => {
    if (checkboxRef.current) {
      if (!checked) {
        checkboxRef.current.indeterminate = indeterminate;
      }

      if (checked) {
        checkboxRef.current.indeterminate = false;
      }
    }
  }, [checkboxRef, checked, indeterminate]);

  return (
    <div
      className={twMerge(
        styles.CheckboxListItem,
        'flex items-center',
        !disabled && 'cursor-pointer',
        className,
      )}
      data-testid="CheckboxInput"
      {...otherProps}
    >
      {/* TODO: Fix issus with accessibility */}
      {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions -- need fix */}
      <div
        className="flex items-center"
        onClick={(e) => {
          if (disableStopPropagation) {
            return;
          }
          e.stopPropagation();
        }}
      >
        <input
          aria-checked={checked}
          checked={checked}
          className="mr-2 cursor-pointer accent-orange-default"
          disabled={disabled}
          onChange={onChange}
          ref={checkboxRef}
          type="checkbox"
        />
      </div>
      <div className={twMerge(disabled && 'text-text-disabled')}>{children}</div>
    </div>
  );
};
