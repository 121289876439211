import type { FocusableElement, FocusableProps, PressEvents } from '@react-types/shared';
import type { ElementType } from 'react';
import type { VariantProps } from 'tailwind-variants';
import { useMemo, useRef } from 'react';

import { useButton } from '@react-aria/button';
import { useFocusRing } from '@react-aria/focus';
import { useHover } from '@react-aria/interactions';
import { mergeProps } from '@react-aria/utils';
import { tv } from 'tailwind-variants';

import type { OverridableComponent, OverrideProps } from '../../types';
import { mergeRefs } from '../../utils';

export const SelectAllButtonVariants = tv({
  slots: {
    base: 'min-h-6 w-full cursor-pointer items-center gap-2 rounded-md px-1 py-2 text-center font-bold',
    startContent: '',
  },
  variants: {
    isDisabled: {
      true: 'cursor-auto text-text-disabled',
    },
    isFocused: {
      true: 'bg-actions-focus',
    },
    isHovered: {
      true: 'bg-actions-hover',
    },
    isPressed: {
      true: 'bg-actions-hover',
    },
    size: {
      md: {},
      sm: {
        base: 'min-h-5 px-0.5 py-1 text-sm',
      },
    },
  },
});

export interface SelectAllButtonPrivateTypeMap<
  AdditionalProps = {},
  DefaultComponent extends ElementType = 'button',
> {
  props: AdditionalProps &
    PressEvents &
    FocusableProps &
    VariantProps<typeof SelectAllButtonVariants> & {};
  defaultComponent: DefaultComponent;
}

export type SelectAllButtonPrivateProps<
  RootComponent extends ElementType = SelectAllButtonPrivateTypeMap['defaultComponent'],
  AdditionalProps = {},
> = OverrideProps<SelectAllButtonPrivateTypeMap<AdditionalProps, RootComponent>, RootComponent>;

/**
 * @private
 */
export const SelectAllButtonPrivate = (<
  BaseComponentType extends ElementType = SelectAllButtonPrivateTypeMap['defaultComponent'],
>(
  inProps: SelectAllButtonPrivateProps<BaseComponentType>,
) => {
  const {
    as: Component = 'button',
    className,
    isDisabled,
    onClick,
    onPress,
    size = 'md',
    children,
    type,
    autoFocus,
    ref,
    ...otherProps
  } = inProps;
  const domRef = useRef<FocusableElement>(null);

  const { focusProps, isFocused } = useFocusRing({ autoFocus });
  const { hoverProps, isHovered } = useHover({ isDisabled });

  const { buttonProps, isPressed } = useButton(
    {
      ...otherProps,
      elementType: typeof Component === 'string' ? (Component as ElementType) : 'button',
      isDisabled,
      onClick,
      onPress,
      type,
    },
    domRef,
  );
  const styles = useMemo(
    () =>
      SelectAllButtonVariants({
        className,
        isDisabled,
        isFocused,
        isHovered,
        isPressed,
        size,
      }),
    [className, isDisabled, isFocused, isHovered, isPressed, size],
  );

  return (
    <Component
      {...mergeProps(buttonProps, focusProps, hoverProps)}
      className={styles.base({
        className,
      })}
      ref={mergeRefs(domRef, ref)}
    >
      {children}
    </Component>
  );
}) as OverridableComponent<SelectAllButtonPrivateTypeMap>;
