/* eslint-disable jsx-a11y/no-autofocus */
import type { AriaCheckboxProps } from '@react-types/checkbox';
import type { ElementType } from 'react';
import type { VariantProps } from 'tailwind-variants';
import { useMemo, useRef } from 'react';

import { useCheckbox } from '@react-aria/checkbox';
import { FocusRing, useFocusRing } from '@react-aria/focus';
import { useHover, usePress } from '@react-aria/interactions';
import { mergeProps } from '@react-aria/utils';
import { VisuallyHidden } from '@react-aria/visually-hidden';
import { useToggleState } from '@react-stately/toggle';
import { tv } from 'tailwind-variants';

import type { InternalComponentProps, OverridableComponent, OverrideProps } from '../../types';
import { dataFocusVisibleClasses } from '../../utils';
import { CheckboxIcon } from './CheckboxIcon';

const checkboxVariants = tv({
  slots: {
    base: 'group relative -m-2 inline-flex max-w-fit cursor-pointer items-center justify-start p-2 tap-highlight-transparent',
    icon: 'z-10 h-3 w-4 text-white opacity-0 group-data-[selected=true]:opacity-100',
    iconWrapper: [
      'relative',
      'inline-flex',
      'items-center',
      'justify-center',
      'flex-shrink-0',
      'overflow-hidden',
      'group-data-[selected=true]:bg-orange-default',
      // before
      "before:content-['']",
      'before:absolute',
      'before:inset-0',
      'before:border-solid',
      'before:border-2',
      'before:box-border',
      'before:border-text',
      'group-data-[selected=true]:before:border-orange-default',
      // after
      "after:content-['']",
      'after:absolute',
      'after:inset-0',
      'after:scale-50',
      'after:opacity-0',
      'after:origin-center',
      'group-data-[selected=true]:after:scale-100',
      'group-data-[selected=true]:after:opacity-100',
      // focus ring
      ...dataFocusVisibleClasses,
    ],
    label: 'relative select-none text-foreground',
  },
  variants: {
    isDisabled: {
      true: {},
    },
    size: {
      md: {
        icon: 'h-3 w-4',
        iconWrapper: [
          'mr-2 size-5',
          'rounded-[calc(theme(borderRadius.md)*0.6)]',
          'before:rounded-[calc(theme(borderRadius.md)*0.6)]',
          'after:rounded-[calc(theme(borderRadius.md)*0.6)]',
        ],
      },
      sm: {
        icon: 'h-2 w-3',
        iconWrapper: [
          'mr-2 size-4',
          'rounded-[calc(theme(borderRadius.md)*0.5)]',
          'before:rounded-[calc(theme(borderRadius.md)*0.5)]',
          'after:rounded-[calc(theme(borderRadius.md)*0.5)]',
        ],
        label: 'text-sm',
      },
    },
  },
});

export interface CheckboxTypeMap<
  AdditionalProps = {
    iconWrapperClassName?: string;
  },
  DefaultComponent extends ElementType = 'label',
> {
  props: AdditionalProps & AriaCheckboxProps & VariantProps<typeof checkboxVariants> & {};
  defaultComponent: DefaultComponent;
}

export type CheckboxProps<
  RootComponent extends ElementType = CheckboxTypeMap['defaultComponent'],
  AdditionalProps = {},
> = OverrideProps<CheckboxTypeMap<AdditionalProps, RootComponent>, RootComponent>;

type InternalCheckboxProps<
  RootComponent extends ElementType = CheckboxTypeMap['defaultComponent'],
  AdditionalProps = {},
> = InternalComponentProps<CheckboxTypeMap<AdditionalProps, RootComponent>>;

export const Checkbox = (<
  BaseComponentType extends ElementType = CheckboxTypeMap['defaultComponent'],
>(
  props: InternalCheckboxProps<BaseComponentType>,
) => {
  const {
    as: Component = 'label',
    className,
    isDisabled,
    autoFocus,
    isIndeterminate,
    children,
    isInvalid,
    size = 'md',
    iconWrapperClassName,
    ref,
  } = props;

  const inputRef = useRef<HTMLInputElement>(null);
  const state = useToggleState(props);
  const { isSelected } = state;
  const { inputProps } = useCheckbox(props, state, inputRef);
  const { focusProps, isFocusVisible, isFocused } = useFocusRing({ autoFocus });
  const { hoverProps, isHovered } = useHover({ isDisabled });
  const { pressProps, isPressed } = usePress({ isDisabled: inputProps.disabled });

  const styles = useMemo(
    () =>
      checkboxVariants({
        isDisabled,
        size,
      }),
    [isDisabled, size],
  );

  return (
    <FocusRing autoFocus={autoFocus}>
      <Component
        {...mergeProps(hoverProps, pressProps)}
        className={styles.base({ className })}
        data-disabled={isDisabled}
        data-focus={isFocused}
        data-focus-visible={isFocusVisible}
        data-hover={isHovered}
        data-indeterminate={isIndeterminate}
        data-invalid={isInvalid}
        data-pressed={isPressed}
        data-readonly={inputProps.readOnly}
        data-selected={isSelected || isIndeterminate}
        ref={ref}
      >
        <VisuallyHidden>
          <input
            {...mergeProps(inputProps, focusProps)}
            ref={inputRef}
          />
        </VisuallyHidden>
        <span className={styles.iconWrapper({ className: iconWrapperClassName })}>
          <CheckboxIcon
            className={styles.icon()}
            isIndeterminate={isIndeterminate}
            isSelected={isSelected}
          />
        </span>
        {children ? <span className={styles.label()}>{children}</span> : null}
      </Component>
    </FocusRing>
  );
}) as OverridableComponent<CheckboxTypeMap>;
