'use client';

import { signIn } from 'next-auth/react';
import { useTranslations } from 'next-intl';

import { Button } from '@org/ui';

export function LoginButton({ callbackUrl }: { callbackUrl?: string }) {
  const t = useTranslations();
  return (
    <Button
      onPress={() =>
        signIn('productiam', {
          callbackUrl,
        })
      }
      size="lg"
      type="submit"
      variant="primary"
    >
      {t('common.landingPage.login')}
    </Button>
  );
}
