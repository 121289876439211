import type { UseQueryOptions } from '@tanstack/react-query';
import { useCallback, useMemo } from 'react';

import { useQueryClient } from '@tanstack/react-query';

import type { APIParams } from '../..';
import type { GetMasterConfigurationByIdError, GetMasterConfigurationVariables } from '../dice';
import type * as Schemas from '../dice/diceSchemas';
import {
  getMasterConfigurationQuery,
  useDeleteMasterConfigurationById,
  useGetMasterConfiguration,
  usePutMasterConfiguration,
} from '../dice';

interface UseMasterConfigurationParams<T = Schemas.MasterConfiguration> extends APIParams {
  /**
   * use query options for `useGetMasterConfigurationById` query
   */
  useQueryOptions?: Omit<
    UseQueryOptions<Schemas.MasterConfiguration, GetMasterConfigurationByIdError, T>,
    'queryKey' | 'queryFn'
  >;
}

export const useMasterConfiguration = <T = Schemas.MasterConfiguration>({
  masterConfigurationId: configId,
  useQueryOptions,
}: UseMasterConfigurationParams<T> = {}) => {
  const queryClient = useQueryClient();

  const { mutateAsync: deleteMasterConfigurationById } = useDeleteMasterConfigurationById();

  const deleteMasterConfiguration = useCallback(
    async (masterConfigId: string) => {
      if (masterConfigId) {
        return await deleteMasterConfigurationById({
          pathParams: {
            id: masterConfigId,
          },
        });
      }
    },
    [deleteMasterConfigurationById],
  );

  const { mutateAsync: updateMasterConfigurationAsync, isPending: isMasterConfigurationUpdating } =
    usePutMasterConfiguration({
      onMutate({ pathParams }) {
        // we need to cancel any ongoing requests for the same master configuration
        // This will override optimistic update when slow network and previous request is still pending

        void queryClient.cancelQueries({
          queryKey: getMasterConfigurationQuery({
            pathParams: {
              masterConfigurationId: pathParams.masterConfigurationId,
            },
          }),
        });
      },
    });

  const variables: GetMasterConfigurationVariables = useMemo(
    () => ({
      pathParams: {
        masterConfigurationId: configId!,
      },
    }),
    [configId],
  );

  const { data: masterConfiguration, isFetching: isMasterConfigurationFetching } =
    useGetMasterConfiguration(variables, {
      ...useQueryOptions,
      enabled: !!configId,
    });

  const updateMasterConfiguration = useCallback(
    async <TData = T>(newMasterConfiguration: TData) => {
      const masterConfig = newMasterConfiguration as Schemas.MasterConfiguration;
      if (!masterConfig.name) {
        throw new Error('Master configuration must not be empty');
      }
      const newConfig: Schemas.MasterConfiguration = {
        ...masterConfig,
        updatedAt: Date.now(),
      };

      const newMasterConfigId = masterConfig.id;

      const masterConfigurationResponse = await updateMasterConfigurationAsync({
        body: newConfig,
        pathParams: {
          masterConfigurationId: newMasterConfigId,
        },
      });

      return { masterConfiguration: newConfig, masterConfigurationResponse };
    },
    [updateMasterConfigurationAsync],
  );

  return {
    deleteMasterConfiguration,
    isMasterConfigurationUpdating,
    isPending: isMasterConfigurationUpdating || isMasterConfigurationFetching,
    masterConfiguration,
    updateMasterConfiguration,
  };
};

export type UseMasterConfigurationReturnType = ReturnType<typeof useMasterConfiguration>;

export type UpdateMasterConfiguration =
  UseMasterConfigurationReturnType['updateMasterConfiguration'];
