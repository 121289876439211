import type { ElementType, ReactNode } from 'react';
import type { VariantProps } from 'tailwind-variants';
import { useCallback, useMemo, useRef, useState } from 'react';

import { ChevronDownIcon } from '@heroicons/react/24/solid';
import { useButton } from '@react-aria/button';
import { useFocusRing } from '@react-aria/focus';
import { useFilter } from '@react-aria/i18n';
import { useHover } from '@react-aria/interactions';
import { mergeProps, useLayoutEffect, useResizeObserver } from '@react-aria/utils';

import type { InternalComponentProps, OverrideProps } from '../../types';
import type { BaseComboboxProps } from './BaseComboboxProps';
import type { AriaMultipleComboboxProps, MultipleComboboxState } from './useMultipleCombobox';
import { createComponent, mergeRefs } from '../../utils';
import { dimensionValue } from '../../utils/dimensionsValue';
import { Chip } from '../Chip';
import { FormControl } from '../FormControl';
import { ListBoxBase, useListBoxLayout } from '../ListBoxBase';
import { Popover } from '../Popover';
import { comboboxVariants } from './comboboxVariants';
import { SelectAllButtonPrivate } from './SelectAllButtonPrivate';
import { useMultipleCombobox } from './useMultipleCombobox';
import { useMultipleComboboxState } from './useMultipleComboboxState';

export interface MultipleComboboxTypeMap<
  AdditionalProps = {},
  DefaultComponent extends ElementType = 'div',
> {
  props: AdditionalProps &
    AriaMultipleComboboxProps<object> &
    VariantProps<typeof comboboxVariants> &
    BaseComboboxProps & {
      formatSelectedText?: (state: MultipleComboboxState<object>) => string;
      enableSelectAll?: boolean;
      selectAllContent?: ReactNode;
    };

  defaultComponent: DefaultComponent;
}

export type MultipleComboboxProps<
  RootComponent extends ElementType = MultipleComboboxTypeMap['defaultComponent'],
  AdditionalProps = {},
> = OverrideProps<MultipleComboboxTypeMap<AdditionalProps, RootComponent>, RootComponent>;

type InternalMultipleComboboxProps<
  RootComponent extends ElementType = MultipleComboboxTypeMap['defaultComponent'],
  AdditionalProps = {},
> = InternalComponentProps<MultipleComboboxTypeMap<AdditionalProps, RootComponent>>;

export const MultipleCombobox = createComponent<MultipleComboboxTypeMap>(
  <BaseComponentType extends ElementType = MultipleComboboxTypeMap['defaultComponent']>(
    inProps: InternalMultipleComboboxProps<BaseComponentType>,
  ) => {
    const {
      as: Component = 'div',
      hideDropdownButton = false,
      label,
      noResult,
      isLoading,
      loadingText,
      helperText,
      helperTextProps: helperTextPropsProp,
      labelProps: labelPropsProp,
      isInvalid,
      isDisabled,
      autoFocus,
      className,
      maxHeight = 320,
      isFullWidth,
      offset = 8,
      placement = 'bottom start',
      menuWidth: customMenuWidth,
      size = 'sm',
      enableSelectAll = false,
      selectAllContent = 'Select all',
      startContent,
      loadingState,
      onLoadMore,
      ref,
      formatSelectedText = ({ selectedItems, selectionManager }) => {
        if (selectionManager.isSelectAll) {
          return 'All items selected';
        }
        if (selectedItems.length === 1) {
          return '1 item selected';
        }
        return `${selectedItems.length} items selected`;
      },
    } = inProps;
    const containerRef = useRef<HTMLDivElement>(null);
    const buttonRef = useRef<HTMLButtonElement>(null);
    const inputRef = useRef<HTMLInputElement>(null);
    const listBoxRef = useRef<HTMLDivElement>(null);
    const selectAllRef = useRef<HTMLButtonElement>(null);
    const popoverRef = useRef<HTMLDivElement>(null);

    const layout = useListBoxLayout(size);

    const { contains } = useFilter({ sensitivity: 'base' });

    const state = useMultipleComboboxState({
      keepMenuOpen: true,
      selectionMode: 'multiple',
      ...inProps,
      // allow menu to open with no results if the user has set an
      // empty state or the combobox is indicated to be loading.
      // This enables us to open the menu and show these two states.
      allowsEmptyCollection: noResult !== undefined || isLoading,
      defaultFilter: contains,
      menuTrigger: 'focus',
    });
    const {
      buttonProps: triggerProps,
      inputProps,
      listBoxProps,
      labelProps,
      descriptionProps,
      errorMessageProps,
    } = useMultipleCombobox(
      {
        ...inProps,
        buttonRef,
        inputRef,
        listBoxRef,
        popoverRef,
      },
      state,
    );

    const { buttonProps } = useButton(triggerProps, buttonRef);
    const { hoverProps, isHovered } = useHover({ isDisabled });
    const { isFocusVisible, isFocused, focusProps } = useFocusRing({
      autoFocus,
      within: true,
    });

    const styles = useMemo(
      () =>
        comboboxVariants({
          className,
          isDisabled,
          isFullWidth,
          size,
        }),
      [className, isDisabled, isFullWidth, size],
    );

    const handleSelectAll = useCallback(() => {
      if (state.selectionManager.isSelectAll) {
        state.setSelectedKeys(new Set([]));
      } else {
        state.setSelectedKeys('all');
      }
      state.close();
    }, [state]);

    // Measure the width of the inputfield and the button to inform the width of the menu (below).
    const [menuWidth, setMenuWidth] = useState<number | undefined>(undefined);

    const onResize = useCallback(() => {
      if (containerRef.current) {
        const buttonWidth = containerRef.current.offsetWidth;
        setMenuWidth(buttonWidth);
      }
    }, []);

    useResizeObserver({
      ref: containerRef,
      onResize,
    });

    useLayoutEffect(onResize, [onResize]);

    const style = {
      width: customMenuWidth ? dimensionValue(customMenuWidth) : menuWidth,
      minWidth: menuWidth,
    };

    return (
      <FormControl
        className={styles.formControl()}
        helperText={helperText}
        helperTextProps={mergeProps(descriptionProps, errorMessageProps, helperTextPropsProp)}
        isFullWidth={isFullWidth}
        isInvalid={isInvalid}
        label={label}
        labelProps={mergeProps(labelProps, labelPropsProp)}
      >
        <Component
          {...mergeProps(hoverProps, focusProps)}
          className={styles.base()}
          data-disabled={isDisabled}
          data-focus={isFocused}
          data-focus-visible={isFocusVisible}
          data-hover={isHovered}
          data-invalid={isInvalid}
          data-open={state.isOpen}
          ref={mergeRefs(containerRef, ref)}
        >
          {startContent}
          {state.selectedItems.length > 0 && (
            <Chip
              className={styles.chip()}
              size={size}
            >
              {formatSelectedText(state)}
            </Chip>
          )}
          <input
            {...inputProps}
            className={styles.input()}
            ref={inputRef}
          />

          {!hideDropdownButton && (
            <button
              type="button"
              {...buttonProps}
              className={styles.button()}
              ref={buttonRef}
            >
              <ChevronDownIcon className={styles.icon()} />
            </button>
          )}
          <Popover
            className={styles.popover()}
            isNonModal
            maxHeight={maxHeight}
            offset={offset}
            placement={placement}
            ref={popoverRef}
            scrollRef={listBoxRef}
            state={state}
            style={style}
            triggerRef={containerRef}
          >
            {enableSelectAll ? (
              <SelectAllButtonPrivate
                isDisabled={isDisabled}
                onPress={handleSelectAll}
                ref={selectAllRef}
                size={size}
                type="button"
              >
                {selectAllContent}
              </SelectAllButtonPrivate>
            ) : null}
            <ListBoxBase
              {...listBoxProps}
              // eslint-disable-next-line jsx-a11y/no-autofocus
              autoFocus={state.focusStrategy ?? undefined}
              className={styles.listBox()}
              disallowEmptySelection
              focusOnPointerEnter
              isLoading={loadingState === 'loading' || loadingState === 'loadingMore'}
              layout={layout}
              loadingContent={loadingText}
              noResultContent={noResult}
              onLoadMore={onLoadMore}
              ref={listBoxRef}
              shouldSelectOnPressUp
              shouldUseVirtualFocus
              showLoadingSpinner={loadingState === 'loadingMore'}
              size={size}
              state={state}
            />
          </Popover>
        </Component>
      </FormControl>
    );
  },
);
