import type { TabListState } from '@react-stately/tabs';
import type { RefObject } from 'react';
import { createContext } from 'react';

import type { TabsProps } from './Tabs';

interface TabsContext<T> {
  tabProps: TabsProps;
  tabState: {
    tabListState: TabListState<T> | null;
    setTabListState: (state: TabListState<T>) => void;
    selectedTab: Element | undefined;
  };
  refs: {
    tablistRef: RefObject<HTMLDivElement | null>;
  };
}
export const TabContext = createContext<TabsContext<any>>(null as unknown as TabsContext<any>);
