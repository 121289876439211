/**
 * Hopefully this will be added directly to the react aria and we can remove this monster
 * @private
 */
import type { AriaComboBoxOptions, ComboBoxAria } from '@react-aria/combobox';
import type { ComboBoxState } from '@react-stately/combobox';
import type { FormValidationState } from '@react-stately/form';
import type { ListState } from '@react-stately/list';
import type { MenuTriggerState } from '@react-stately/menu';
import type { MenuTriggerAction } from '@react-types/combobox';
import type {
  AriaLabelingProps,
  CollectionBase,
  DOMProps,
  FocusableProps,
  FocusStrategy,
  HelpTextProps,
  InputBase,
  Key,
  KeyboardDelegate,
  LabelableProps,
  MultipleSelection,
  Node,
  Selection,
  TextInputBase,
  Validation,
} from '@react-types/shared';
import type * as React from 'react';

import { useComboBox } from '@react-aria/combobox';

export interface AriaMultipleComboboxProps<T>
  extends MultipleComboboxProps<T>,
    DOMProps,
    AriaLabelingProps {
  /** Whether keyboard navigation is circular. */
  shouldFocusWrap?: boolean;
}

export interface MultipleComboboxProps<T>
  extends CollectionBase<T>,
    InputBase,
    TextInputBase,
    Validation<any>,
    FocusableProps,
    LabelableProps,
    HelpTextProps,
    Omit<MultipleSelection, 'disallowEmptySelection'> {
  /** Whether the ComboBox allows a non-item matching input value to be set. */
  allowsCustomValue?: boolean;
  /** The list of ComboBox items (uncontrolled). */
  defaultItems?: Iterable<T>;
  /** The default value of the ComboBox input (uncontrolled). */
  defaultInputValue?: string;
  /** The value of the ComboBox input (controlled). */
  inputValue?: string;
  /** Sets the open state of the menu. */
  isOpen?: boolean;
  /** The list of ComboBox items (controlled). */
  items?: Iterable<T>;
  /**
   * The interaction required to display the ComboBox menu.
   *
   * @default 'input'
   */
  menuTrigger?: MenuTriggerAction;
  /**
   * The name of the input element, used when submitting an HTML form. See [MDN](https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input#htmlattrdefname).
   */
  name?: string;
  /** Handler that is called when the ComboBox input value changes. */
  onInputChange?: (value: string) => void;
  /** Method that is called when the open state of the menu changes. Returns the new open state and the action that caused the opening of the menu. */
  onOpenChange?: (isOpen: boolean, menuTrigger?: MenuTriggerAction) => void;
}

export interface AriaMultipleComboboxOptions<T> extends AriaMultipleComboboxProps<T> {
  /** The ref for the input element. */
  inputRef: React.RefObject<HTMLInputElement | null>;
  /** The ref for the list box popover. */
  popoverRef: React.RefObject<Element | null>;
  /** The ref for the list box. */
  listBoxRef: React.RefObject<HTMLElement | null>;
  /** The ref for the optional list box popup trigger button.  */
  buttonRef?: React.RefObject<Element | null>;
  /** An optional keyboard delegate implementation, to override the default. */
  keyboardDelegate?: KeyboardDelegate;
}

export interface MultipleComboboxState<T>
  extends ListState<T>,
    MenuTriggerState,
    FormValidationState {
  /** The current value of the combo box input. */
  inputValue: string;
  /** Sets the value of the combo box input. */
  setInputValue: (value: string) => void;
  /** Selects the currently focused item and updates the input value. */
  commit: () => void;
  /** Opens the menu. */
  open: (focusStrategy?: FocusStrategy | null, trigger?: MenuTriggerAction | null) => void;
  /** Toggles the menu. */
  toggle: (focusStrategy?: FocusStrategy | null, trigger?: MenuTriggerAction | null) => void;
  /** Resets the input value to the previously selected item's text if any and closes the menu.  */
  revert: () => void;
  /** The value of the currently selected item. */
  readonly selectedItems: Node<T>[];
  /** The key for the currently selected item. */
  readonly selectedKeys: Selection;
  /** Sets the selected keys. */
  setSelectedKeys: (keys: Set<Key> | 'all') => void;
  /** Whether the select is currently focused. */
  readonly isFocused: boolean;
  /** Sets whether the select is focused. */
  setFocused: (isFocused: boolean) => void;
  /** Reset input value. */
  resetInputValue: () => void;
}
/**
 * Once multiCombobox lands in react aria remove this
 * @internal
 */
export function useMultipleCombobox<T>(
  props: AriaMultipleComboboxOptions<T>,
  state: MultipleComboboxState<T>,
): ComboBoxAria<T> {
  return useComboBox(props as AriaComboBoxOptions<T>, state as unknown as ComboBoxState<T>);
}
