'use client';

import type { FC } from 'react';
import Link from 'next/link';
import { usePathname } from 'next/navigation';

import { useTranslations } from 'next-intl';

import { Tab, TabList, Tabs } from '@org/design';
import { aggregated } from '@org/query';

import { usePathParams } from '~/hooks';

export const ReportTabs: FC = () => {
  const pathname = usePathname();
  const pathParams = usePathParams();
  const { masterConfiguration } = aggregated.useMasterConfiguration(pathParams);

  const t = useTranslations();
  const lastPart = pathname.split('/').at(-1);
  const href = pathname.split('/').toSpliced(-1).join('/');

  return (
    <div className="mb-3 mt-1">
      <Tabs
        selectedKey={lastPart}
        variant="underlined"
      >
        <div className="flex justify-between">
          <TabList className="space-x-4">
            <Tab
              as={Link}
              href={`${href}/bab`}
              key="bab"
            >
              {t('main.reportPanels.tabName.BAB')}
            </Tab>

            <Tab
              as={Link}
              href={`${href}/interest`}
              key="interest"
            >
              {t('main.reportPanels.tabName.INTEREST')}
            </Tab>
            <Tab
              as={Link}
              href={`${href}/cost-unit`}
              key="cost-unit"
            >
              {t('main.reportPanels.tabName.COST_UNIT')}
            </Tab>
            {masterConfiguration?.imputedTradeTaxConfig ? (
              <Tab
                as={Link}
                href={`${href}/trade-tax`}
                key="trade-tax"
              >
                {t('main.reportPanels.tabName.TRADE_TAX')}
              </Tab>
            ) : null}
            <Tab
              as={Link}
              href={`${href}/price-sheet`}
              key="price-sheet"
            >
              {t('main.reportPanels.tabName.PRICE_SHEET')}
            </Tab>
          </TabList>
        </div>
      </Tabs>
    </div>
  );
};

ReportTabs.displayName = 'ReportTabs';
