import type { ListState } from '@react-stately/list';
import { createContext } from 'react';

interface ListBoxContextValue {
  state: ListState<unknown>;
  shouldFocusOnHover: boolean;
  shouldUseVirtualFocus: boolean;
}

export const ListBoxContext = createContext<ListBoxContextValue | null>(null);
