import type { ForwardedRef, Ref, RefObject } from 'react';

/**
 * Merges multiple refs into one. Works with either callback or object refs.
 */
export function mergeRefs<T>(
  ...refs: (RefObject<T | undefined> | ForwardedRef<T> | null | undefined)[]
): Ref<T> {
  if (refs.length === 1) {
    return refs.at(0) as Ref<T>;
  }

  return (value: T | null) => {
    for (const ref of refs) {
      if (typeof ref === 'function') {
        ref(value);
      } else if (ref != null) {
        ref.current = value;
      }
    }
  };
}
