/* eslint-disable jsx-a11y/no-autofocus */
import type { CustomCellEditorProps } from '@ag-grid-community/react';
import type { FC, KeyboardEvent } from 'react';
import { useCallback, useEffect, useRef } from 'react';

import { useGridCellEditor } from '@ag-grid-community/react';

import { NumberInput } from '../NumberInput';

export interface NumericCellFieldProps extends CustomCellEditorProps {
  onValueChange: (value: unknown) => void;
  defaultValue?: number;
  decimalScale?: number;
  fixedDecimalScale?: boolean;
}

const KEY_BACKSPACE = 'Backspace';
const KEY_F2 = 'F2';
const KEY_ENTER = 'Enter';
const KEY_TAB = 'Tab';

const isLeftOrRight = (event: KeyboardEvent) => ['ArrowLeft', 'ArrowLeft'].includes(event.key);
const isBackspace = (event: KeyboardEvent) => event.key === KEY_BACKSPACE;
const finishedEditingPressed = (event: KeyboardEvent) => {
  const key = event.key;
  return key === KEY_ENTER || key === KEY_TAB;
};

export const NumericCellField: FC<NumericCellFieldProps> = (props) => {
  const { decimalScale, fixedDecimalScale, value, onValueChange, eventKey, stopEditing } = props;
  const refInput = useRef<HTMLInputElement>(null);

  const updateValue = useCallback(
    (val?: number) => {
      onValueChange(val ?? null);
    },
    [onValueChange],
  );

  useEffect(() => {
    let startValue;
    let highlightAllOnFocus = true;

    if (eventKey === KEY_BACKSPACE) {
      // if backspace or delete pressed, we clear the cell
      startValue = '';
    } else if (eventKey && eventKey.length === 1) {
      // if a letter was pressed, we start with the letter
      startValue = eventKey;
      highlightAllOnFocus = false;
    } else {
      // otherwise we start with the current value
      startValue = value;
      if (eventKey === KEY_F2) {
        highlightAllOnFocus = false;
      }
    }
    if (startValue == null) {
      startValue = '';
    }

    updateValue(startValue);

    // get ref from React component
    const eInput = refInput.current!;
    eInput.focus();
    if (highlightAllOnFocus) {
      eInput.select();
    } else {
      // when we started editing, we want the caret at the end, not the start.
      // this comes into play in two scenarios:
      //   a) when user hits F2
      //   b) when user hits a printable character
      const length = eInput.value ? eInput.value.length : 0;
      if (length > 0) {
        eInput.setSelectionRange(length, length);
      }
    }

    // eslint-disable-next-line react-compiler/react-compiler
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onKeyDown = (event: KeyboardEvent<HTMLInputElement>) => {
    if (isLeftOrRight(event) || isBackspace(event)) {
      event.stopPropagation();
      return;
    }

    if (finishedEditingPressed(event)) {
      stopEditing();
    }
  };

  const isCancelAfterEnd = useCallback(() => value == null, [value]);

  useGridCellEditor({
    isCancelAfterEnd,
  });

  return (
    <NumberInput
      autoFocus
      decimalScale={decimalScale}
      fixedDecimalScale={fixedDecimalScale}
      getInputRef={refInput}
      onChange={updateValue}
      onKeyDown={onKeyDown}
      value={value ?? ''}
    />
  );
};
