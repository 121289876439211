import Big from 'big.js';

// FIXME: Once decimal128 is available replace big.js
// @see https://tc39.es/proposal-decimal/
export const preciseSum = (values: number[]) => {
  const allNumbers = values.every((value) => typeof value === 'number');

  if (!allNumbers) {
    return null;
  }

  const sum = values
    .reduce((acc, current) => {
      if (typeof current === 'number') {
        return acc.plus(current);
      }
      return acc;
    }, Big(0))
    .toNumber();

  return sum;
};
