import type { CellClassParams, RowClassParams } from '@ag-grid-community/core';

const BOLD_ROW_NAMES = new Set([
  'TOTAL_COSTS',
  'TOTAL_COSTS_AFTER_COST_ALLOCATION',
  'TOTAL_REVENUE',
  'TOTAL_REVENUE_AFTER_REVENUE_ALLOCATION',
  'REIMBURSEMENT_NEEDS',
]);

const getFontBoldClass = (
  { node, data }: CellClassParams | RowClassParams,
  rowName?: string,
): string =>
  (node.level <= 1 && Boolean(data?.disableBold) === false) ||
  (rowName && BOLD_ROW_NAMES.has(rowName))
    ? 'font-bold'
    : '';

export const getFontBoldClassForLevel =
  (level: number) =>
  ({ node, data }: CellClassParams | RowClassParams) =>
    node.level === level && Boolean(data?.disableBold) === false ? 'font-bold' : '';

const getHighlightedClass = ({ data, node: { level } }: RowClassParams) => {
  if (data?.disableHighlight || level !== 0) {
    return '';
  }
  return 'bg-highlighted';
};

export const getExcelHighlightedClass = ({ data, node: { level } }: RowClassParams) => {
  if (data?.disableHighlight || level !== 0) {
    return 'white-background';
  }
  return 'excel-bg-highlighted';
};

const getWhiteClass = ({ data, node: { level } }: RowClassParams) => {
  if (data?.disableHighlight || level !== 0) {
    return 'bg-white';
  }
  return '';
};

export const getRowClasses =
  (...extraRowClassFunctions: ((rowClassParams: RowClassParams) => string)[]) =>
  (rowClassParams: RowClassParams) => [
    getHighlightedClass(rowClassParams),
    getWhiteClass(rowClassParams),
    ...extraRowClassFunctions.map((rowClassFunction) => rowClassFunction(rowClassParams)),
  ];

export const getExcelCellClasses =
  (...extraRowClassFunctions: ((rowClassParams: RowClassParams) => string)[]) =>
  (rowClassParams: RowClassParams) => [
    getExcelHighlightedClass(rowClassParams),
    ...extraRowClassFunctions.map((rowClassFunction) => rowClassFunction(rowClassParams)),
  ];

export const getCellClasses =
  (
    ...extraCellClassFunctions: ((
      cellClassParams: CellClassParams | RowClassParams,
    ) => string | string[])[]
  ) =>
  (cellClassParams: CellClassParams | RowClassParams) => [
    getExcelHighlightedClass(cellClassParams),
    getFontBoldClass(cellClassParams),
    ...extraCellClassFunctions.flatMap((cellClassFunction) => cellClassFunction(cellClassParams)),
  ];
